import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from '../../validationSchema/loginSchema';
import { FormInput } from '../../../src/utility/FormInput';
import { FormCheckbox } from '../../../src/utility/FormCheckbox';
import { useDispatch, useSelector } from "react-redux";
import { auth, authConstant } from '../../actions/auth.action';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Message  from '../../components/Message/Message';

const schema = loginSchema;

function LoginForm(props) {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);
    const localStorageData = JSON.parse(localStorage.getItem('data'));
    const [focusedInput, setFocusedInput] = useState(null);

    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb|Login';

        if (authState.userDetails.id) {
            const redirectTo = location.state?.from?.pathname || "/profile";
            return navigate(redirectTo);
        }
    },[authState]);

    const onSubmit = async (data) => {
        dispatch({ type: authConstant.SIGNIN_REQUEST });
        dispatch(auth.signIn(data));
    }

    const togglePassword = () => {
        setShowPassword(showPassword === true ? false : true)
        const loginPasswordEl = document.getElementById(props.id);
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }

    const handleInputFocus = (fieldName) => {
        setFocusedInput(fieldName);
    }

    const handleInputBlur = () => {
        setFocusedInput(null);
    }
    
    return (
        <>
            <h3>Log in</h3>
            { (authState.error.type === 'signin') ? <Message /> : '' }
            <form onSubmit={handleSubmit(onSubmit)} class="needs-validation">
                {/* <!-- Login form --> */}
                <div class="form-group position-relative">
                    <FormInput
                        register={register('email')}
                        label="Email"
                        // error={errors?.email}
                        name="email"
                        type="text"
                        identity="email"
                        autoComplete="off"
                        defaultValue={localStorageData !== null ? localStorageData.email : ''}
                        onFocus={() => handleInputFocus('email')}
                        onBlur={handleInputBlur}
                    />
                        {focusedInput !== 'email' && errors?.email && (
                            <div className="error-message" style={{color:'#d62727'}}>{errors.email.message}</div>
                        )}
                </div>

                <div class="form-group position-relative" id="show_hide_pass">
                    <FormInput
                        register={register('password')}
                        label="Password"
                        // error={errors?.password}
                        name="password"
                        type="password"
                        identity="password"
                        autoComplete="off"
                        class="custom-input-floating login-password"
                        id={props.id}
                        defaultValue={localStorageData !== null ? localStorageData.password : ''}
                        onFocus={() => handleInputFocus('password')}
                        onBlur={handleInputBlur}
                    />
                        {focusedInput !== 'password' && errors?.password && (
                            <div className="error-message"  style={{color:'#d62727'}}>{errors.password.message}</div>
                        )}
                    <div class="input-group-addon">
                        <a href id="showhide" onClick={() => togglePassword()}><i className={`fa fa-eye${showPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                    </div>  

                </div>

                <div class="form-check d-flex mt-2 pl-0 justify-content-between">
                    <div class="bottom-header-form-check mt-1">
                        <FormCheckbox
                            register={register('isRemember')}
                            label="Keep me signed in"
                            error={errors?.isRemember}
                            name="isRemember"
                            type="checkbox"
                            identity="isRemember"
                            autoComplete="off"
                            class="box-check login-text"
                            defaultChecked={ localStorageData !== null && localStorageData.isRemember === true ? true : false}
                        />
                        
                    </div>

                    <div class="reset_pass">
                        <Link to='/login/forgot'>Forgot password?</Link>
                    </div>
                </div>
                {authState.submitted === true && (
                    <button className="btn btn-dark signin" disabled>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>{" "}
                    Loading...
                    </button>
                )}
                {authState.submitted === false && (
                    <button type="submit" className="btn btn-dark signin">
                    Sign In
                    </button>
                )}
            </form>
        </>
    );
}

export default LoginForm;