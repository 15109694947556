import * as yup from "yup";

const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const registerSchema = yup.object().shape({
  first_name: yup.string().min(3, 'First Name must be at least 3 characters').max(50).required("Please enter the first name"),
  last_name: yup.string().min(1, 'Last Name must be at least 1 character').max(50).required("Please enter the last name"),
  email: yup.string().email('Email must be a valid email').matches(emailRegx, "Please enter the email"),
  password: yup.string().min(8, 'Password must be at least 8 characters').max(50).required("Please enter the password"),
  confirm_password: yup.string().required("Please enter the confirm password").oneOf([yup.ref('password'), null], 'Confirm password must match with password'),

});
