import React from "react";

function FormCheckbox({ register, label, error, ...checkboxProps }) { 
  return (
    <>
        <input
            {...register}
            {...checkboxProps}
        />
        <label className="custom-text-floating fname" htmlFor="validationCustom01">{label}</label>
        {error && <div className="invalid-feedback">{error.message}</div>}
    </>
  );
}

export { FormCheckbox }