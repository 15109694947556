import React from "react";

function Checkbox(props) { 
    const { id, name, value, handleChange, checked, className, ...checkboxProps } = props;
    return (
        <div>
            <input
                id={id}
                type="checkbox"
                name={name}
                value={value}
                onChange={handleChange}
                checked={checked}
                className={className}
                {...checkboxProps}
            />
        </div>
    );
}

export default Checkbox;
