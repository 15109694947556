import React from 'react'
import './style.css'
import { Link } from 'react-router-dom';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from '../../utility/FormInput';
import { auth, authConstant } from '../../actions/auth.action';
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';


const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const schema = yup.object().shape({
  email: yup.string().email('Email must be a valid email').matches(emailRegx, "Please enter the email"),
});

function Footer(props) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [focusedInput, setFocusedInput] = useState(null);

  const { register, handleSubmit, formState: { errors }, } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({ type: authConstant.EMAIL_SUBSCRIPTION_REQUEST });
    dispatch(auth.emailSubscription(data));
  }

  const handleInputFocus = (fieldName) => {
      setFocusedInput(fieldName);
  }

  const handleInputBlur = () => {
      setFocusedInput(null);
  }

  return (
    <div>
      <footer>
        <div className="container-fluid">
          <div className="row mx-4">
            <div className="col-12 col-md-3 news-lt-mob mo-aligncenter">
              <div className="widget news-ltr">
                <h5 className="widgetheading d-none d-md-block">
                  Stay in Touch
                </h5>
                {/* <!--<p>Receive offers & updates: </p>--> */}
                {/* <form className="subscribe">
                  <div className="input-append2">
                    <input
                      className="span2"
                      id="appendedInputButton"
                      type="text"
                      placeholder="Enter your email..."
                    />
                    <button className="btn btn-theme " type="submit">
                       SUBSCRIBE
                    </button>
                  </div>
                </form> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-3  col-12 eql abt-u">
              <div className="widget">
                <h5 className="widgetheading">About ShopThumb</h5>
                <ul className="link-list">
                  <li>
                  <Link to='/aboutUs'>About Us</Link>
                  </li>
                  {/* <li>
                    <a href="#">Our Story</a>
                  </li> */}
                  {/* <li>
                    <a href="#">How ShopThumb works</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">Affiliates</a>
                  </li>
                  <li>
                    <a href="#">Advertise with us</a>
                  </li>
                  <li>
                    <a href="#">Sitemap</a>
                  </li>
                  <li>
                    <a href="#">Press</a>
                  </li> */}
                
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3  col-12 eql cus-ser">
              <div className="widget">
                <h5 className="widgetheading">CUSTOMER SERVICE</h5>
                <ul className="link-list">
                  <li>
                    <Link to='/contact-us'>Contact Us</Link>
                  </li>
                  {/* <li>
                    <a href="#">FAQ</a>
                  </li> */}
                  <li>
                    <a href="#">Terms of use</a>
                  </li>
                  <li>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                  </li>
                  {/* <li>
                    <a href="#">Brand Directory</a>
                  </li>
                  <li>
                    <a href="#">Store Directory</a>
                  </li>
                  <li>
                    <a href="#">Top Searches</a>
                  </li>
                  <li>
                    <a href="#">Quick Links</a>
                  </li>
                  <li>
                    <a href="#">Feedback</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 d-none d-md-block">
              <div className="widget news-ltr">
                <h5 className="widgetheading">Stay in Touch</h5>
                {/* <!-- <p class="text-center">Receive offers & updates: </p> --> */}
                <form className="subscribe" onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className="input-append2 col-md-6">
                      <FormInput
                        type="text"
                        identity="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        register={register('email')}
                        error={errors?.email}
                        className={`form-control custom-input-floating ${ errors?.email ? 'is-invalid' : ''}`}
                        onFocus={() => handleInputFocus('email')}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    <div className='col-md-4'>
                      {authState.submitted === true ?  
                      <button className="btn btn-theme subscribe-btn " disabled>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>{" "}
                        Loading...
                      </button>
                      : 
                      <button className="btn btn-theme subscribe-btn" type="submit" style={{ width: '100px', color: 'white', backgroundColor: 'black',  marginLeft: '-30px'}}>
                          SUBSCRIBE
                      </button>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-12 ">
              <div className="conct_app">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="widgetheading">Connect</h5>
                    <ul className="link-list">
                      <li>
                        <a href="https://facebook.com/shopthumb" target='_blank'>
                          <i
                            className="fa fa-facebook-official"
                            aria-hidden="true"
                          ></i>
                          <span>Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/shopthumb" target='_blank'>
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                          <span>Twitter</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pinterest.com/shopthumb" target='_blank'>
                          <i className="fa fa-pinterest" aria-hidden="true"></i>
                          <span>Pinterest</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/shopthumb_" target='_blank'>
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                          <span>Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sub-footer">
          <div className="container-fluid ">
            <p className="copyright">
              <span>&copy; 2019 ShopThumb</span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
