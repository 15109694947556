import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

function EmailVerificationMessgae() {
    const authState = useSelector((state) => state.auth);
    
    
    return (
        <section className="col-lg-5 mx-auto my-5 success-box">
        <div>
            <h5 className='mb-4'>An email has been sent to you at {authState.userDetails.email}. Please click on link to verify YOUR email ADDRESS.</h5>
                <Link className="btn  btn-dark signin"to='/login'>Login</Link>
        </div>
    </section>
    );
}

export default EmailVerificationMessgae;