import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
/* Importing jquery here... */
import '../../utility/jquery/bootstrap-colorselector.min.js';
import '../../utility/jquery/simpleGallery.js';
import '../../utility/jquery/simpleLens.js';
import '../../utility/jquery/custom.js';
// import '../../utility/jquery/imgHover.js';
/* Importing css here... */
import '../../utility/jquery/bootstrap-colorselector.min.css';
import '../../utility/jquery/simpleLens.css';
import './QuickViewModal.css';


function ProductModal(props) {
    const proDetails = props.productDetails;
    const favorite = props.favorite;

    const handleQty = (type) => {
        let qty = parseInt(document.getElementById('quantity').value);

        if (type === 'incr') {
            qty += 1;
        } else {
            if (qty > 0) { qty -= 1; }
        }

        document.getElementById('quantity').value = qty;
    }

    const editorToHtml = (desc) => {
        return {__html: desc};
    }
    
    let orignalImg = '';
    if(proDetails?.vendor_id > 1) {
        orignalImg = proDetails.proPrimaryMedia?.image_path;
    } else {
        orignalImg = proDetails.proPrimaryMedia?.primary_image;
    }

    const handleImage = (e) => {
        document.getElementById('primary-image').src = e.target.src
      }

    return (
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Body>
                <div className="quick-vmodal" id="quick-view-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog m-0">
                        <div className="modal-content border-0">
                            <div className="modal-body">
                                <div className="button close" data-dismiss="modal" aria-hidden="true" onClick={props.handleClose}>
                                    <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" width="18px" height="18px">
                                        <path
                                            d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" />
                                    </svg>
                                </div>

                                <div className="row">
                                    {/* <!-- Modal view slider --> */}
                                    <div className="col-md-5 col-sm-6 col-xs-12">
                                        <div className="aa-product-view-slider">
                                            <div className="simpleLens-gallery-container">
                                                <div className="simpleLens-container">
                                                    <div className="simpleLens-big-image-container" id="overlay" onmousemove={(event) => zoomIn(event)}>
                                                        <a className="simpleLens-lens-image" data-lens-image="images/quick-img6.jpg">
                                                            <img loading="lazy" id='primary-image' className="img-fluid simpleLens-big-image" onmousemove={(event) => zoomIn(event)} onmouseout={()=> zoomOut()} src={`${orignalImg}`} />

                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <!-- DEVELOPER NOTE  :: Use Timthumb for resize image of thumbnail | medium |large--> */}
                                                <div className="simpleLens-thumbnails-container">
                                                    <a className="simpleLens-thumbnail-wrapper"
                                                        data-lens-image={`${orignalImg}`}
                                                        data-big-image={`${orignalImg}`}
                                                       >
                                                        <img loading="lazy"  src={`${orignalImg}`}  onMouseOver={(e)=>handleImage(e)}/>
                                                    </a>
                                                    {proDetails.productMedia?.length > 0 ? proDetails.productMedia?.map((media, i) => {
                                                        return (
                                                            <a className="simpleLens-thumbnail-wrapper"
                                                                data-lens-image={`${media.media_images}`}
                                                                data-big-image={`${media.media_images}`}>
                                                                <img loading="lazy" src={`${media.media_images}`}  onMouseOver={(e)=>handleImage(e)} />
                                                            </a> )
                                                        
                                                    }) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Modal view content --> */}
                                    <div className="col-md-7	col-sm-6 col-xs-12">
                                        <div className="aa-product-view-content">
                                            <h3>{proDetails?.brand_name}</h3>
                                            <div className="aa-price-block">
                                                <p className="aa-prod-category m-0">
                                                    <a target="_blank" href={proDetails?.product_url}>{proDetails?.name}</a>
                                                </p>
                                                <p className="aa-product-avilability m-0">Avilability: {proDetails?.quantity > 0 ? <span id="inStock">In stock</span> : <span id="outOfStock">Out of stock</span>}</p>
                                            </div>

                                            <div className="ad_option">
                                                <div className="aa-price-block">
                                                    <h4>Price :</h4>
                                                    <span className="aa-product-view-price">${proDetails?.compare_price}</span>
                                                </div>
                                                {/* <div className="aa-prod-view-size">
                                                    <h4>Size :</h4>
                                                    {proDetails?.option?.length > 0 ? proDetails.option.map((data) => {
                                                        if (data.name === 'size') {
                                                            let sizeArr = data.vals.split(',');
                                                            let indents = [];

                                                            for(let i=0; i < sizeArr.length; i++) {
                                                                indents.push(<a href="javascript:void(0);">{sizeArr[i]}</a>);
                                                            }

                                                            return indents;
                                                        }
                                                    })
                                                    : ''}
                                                </div>
                                                <div className="aa-prod-view-color">
                                                    <h4>Color :</h4>
                                                    <select id="colorselector_2">
                                                        {proDetails?.option?.length > 0 ? proDetails.option.map((data) => {
                                                            if (data.name === 'color') {
                                                                let colorArr = data.vals.split(',');
                                                                let indents = [];
                                                                
                                                                {colorArr?.length > 0 ? colorArr.map((color, index) => {
                                                                    indents.push(<option defaultValue="106" data-color="#A0522D" key={index}>{color}</option>);
                                                                })
                                                                : ''}

                                                                return indents;
                                                            }
                                                        })
                                                        : ''}
                                                        <option defaultValue="106" data-color="#A0522D">sienna</option>
                                                        <option defaultValue="47" data-color="#CD5C5C" selected="selected">indianred
                                                        </option>
                                                        <option defaultValue="87" data-color="#FF4500">orangered</option>
                                                        <option defaultValue="17" data-color="#008B8B">darkcyan</option>
                                                        <option defaultValue="18" data-color="#B8860B">darkgoldenrod</option>
                                                        <option defaultValue="68" data-color="#32CD32">limegreen</option>
                                                        <option defaultValue="42" data-color="#FFD700">gold</option>
                                                    </select>
                                                </div>
                                                <div className="aa-prod-quantity">
                                                    <h4>Qty :</h4>
                                                    <div className="number-input">
                                                        <button className="minus" style={{color: 'white'}} onClick={(e) => handleQty('decr')}>-</button>
                                                        <input id="quantity"  className="quantity" min="1" max="49" name="quantity" value='1'
                                                            type="number" readOnly/>
                                                        <button  className="plus"  style={{color: 'white'}} onClick={(e) => handleQty('incr')}>+</button>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className="quick-desc">
                                                <h4>Details:</h4>
                                                <div dangerouslySetInnerHTML={editorToHtml(proDetails?.description)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="row no-margin">
                                            <div className="aa-prod-view-bottom bor-top">
                                                <Link to="#" className="col-md-6 col-xs-6 aa-add-to-fav-btn" onClick={(e) => props.handleFavorite(proDetails.id, e)}>{favorite.content}</Link>
                                                <a target="_blank" href={proDetails?.product_url} className="col-md-6 col-sm-6 col-xs-6 aa-add-to-cart-btn pull-right">BUY
                                                    NOW AT {proDetails?.store_name}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProductModal;