import { toast } from "react-toastify";
import { categoryService } from "../adapters/category.services";

export const categoryConstant = {
    GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
    
    SET_CATEGORY_URL_VALUE: "SET_CATEGORY_URL_VALUE",

    GET_SEARCH_REQUEST: "GET_SEARCH_REQUEST",
    GET_SEARCH_SUCCESS: "GET_SEARCH_SUCCESS",
    GET_SEARCH_FAILURE: "GET_SEARCH_FAILURE",

    SET_SEARCH_KEYWORD_VALUE: "SET_SEARCH_KEYWORD_VALUE",

};

export const categoryAction = {
    getCategories,
    getSearchFilterData,
};


function getCategories() {
    return (dispatch) => {
        dispatch(request());
        categoryService
        .getCategories()
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorCategory(error));
        });
    };
  
    function errorCategory(error) {
        return { type: categoryConstant.GET_CATEGORY_FAILURE, error: error };
    }
  
    function request() {
        return { type: categoryConstant.GET_CATEGORY_REQUEST };
    }
  
    function success(data) {
        return { type: categoryConstant.GET_CATEGORY_SUCCESS, payload: data };
    }
}

function getSearchFilterData(params) {
    return (dispatch) => {
        dispatch(request());
        categoryService
        .getSearchFilterData(params)
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorCategory(error));
        });
    };
  
    function errorCategory(error) {
        return { type: categoryConstant.GET_SEARCH_FAILURE, error: error };
    }
  
    function request() {
        return { type: categoryConstant.GET_SEARCH_REQUEST };
    }
  
    function success(data) {
        return { type: categoryConstant.GET_SEARCH_SUCCESS, payload: data };
    }
}

