import React, { useState,  useEffect } from 'react'
import { useSelector } from "react-redux";
import ProducstList from './ProducstList';

function SearchProductList() {
    // const searchKeywordUrl = useSelector((state) => state.category?.searchKeywordUrl);
    const homepage = useSelector((state) => state.homepage?.homepageKey);
    const [homepageKey, setHomepageKey] = useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const searchTerm = searchParams.get('keyword');

    useEffect(() => {
        setHomepageKey(homepage);
    }, []);
    
    
    return (
        <ProducstList flag='searchKey' searchKeyword={searchTerm} homepage={homepageKey}/>
    )

}

export default SearchProductList;