import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authService } from '../../adapters/auth.services';

function EmailVerification(props) {
    console.log('props-', props);
    const { id } = useParams();
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        if(! id) return;

        if (props?.page === 'email') {
            const response = authService.verifyMail({id});
            response.then(data => setStatus(data?.data?.message));
        }

        if (props?.page === 'subscription-email') {
            const response = authService.emailSubscriptionVerify({id});
            response.then(data => setStatus(data?.data?.message)).catch(error => {
                console.error('Email verification error: ' + error)
            });
        }
        
    }, [id]);
    
    return (
        <section className="col-lg-5 mx-auto my-5">
            <div>
                <h5 className="text-center text-dark"> {status === 'loading' ? 'Loading...' : status}</h5>
                {props?.page === 'subscription-email' ? <Link className="btn btn-dark signin"to='/'>Home</Link> : <Link className="btn btn-dark signin"to='/login'>Login</Link>}
            </div>
        </section>
    );
}

export default EmailVerification;