import { get, post } from "./xhr";
import { headers } from "../helpers/headers";

export const categoryService = {
    getCategories,
    getSearchFilterData,
};

function getCategories() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/categories`);

    return get(url, headers);
}

function getSearchFilterData(params) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/search/keyword`);

    for (let key in params) {
        url.searchParams.append(key, params[key]);
    }

    return get(url, headers);
}

