import { productConstant } from "../actions/productList.action";

const initialState = {
  loading: false,
  products: [],
  productDetails: [],
  error: null,
  mediaList: [],
  brandList: [],
  storeList: [],
  colorList:[],
  productOptionAttrList: [],
  addToFavorite: [],
  favoritesData: [],
  colorMedia: []
};

export function productReducer(state = initialState, action) {
  switch (action.type) {
    case productConstant.GET_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case productConstant.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        productDetails: [],
      };
    case productConstant.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        products: []
      };
    case productConstant.ADD_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case productConstant.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: []
      };
    case productConstant.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case productConstant.GET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: action.payload
      };
    case productConstant.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case productConstant.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        mediaList: action.payload,
      };
    case productConstant.DELETE_MEDIA_SUCCESS: {
      const media = state.mediaList;
      media.splice(state.mediaList.findIndex((el) => el.media_id == action.payload), 1);
      return {
        ...state,
        mediaList: [...media],
        loading: false
      }
    }
    case productConstant.DELETE_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }
    case productConstant.ADD_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaList: [...state.mediaList, action.payload.media_details.shift()]
      }
    }
    case productConstant.ADD_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case productConstant.PRIMARY_MEDIA_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case productConstant.PRIMARY_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaList: state.mediaList.map((media) => {
          if (media.id != action.payload) {
            return media
          } else {
            media.is_default = 1;
            return media;
          }
        })
      }
    }
    case productConstant.PRIMARY_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case productConstant.GET_PRODUCT_BY_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    
    case productConstant.GET_PRODUCT_OPTION_ATTR_SUCCESS:
      return {
        ...state,
        loading: false,
        productOptionAttrList: action.payload,
      };


    case productConstant.GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brandList: action.payload,
      };

    case productConstant.GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        storeList: action.payload,
      };

    case productConstant.GET_COLOR_SUCCESS:
    return {
      ...state,
      loading: false,
      colorList: action.payload,
    };

    case productConstant.GET_ADDTOFAV_FILTTER_SUCCESS:
      return {
        ...state,
        loading: false,
        favoritesData: action.payload,
      };
      case productConstant.GET_ALLITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        allItems: action.payload,
      };
      
    case productConstant.ADD_TO_FAVORITE_REQUEST:
      return {
        ...state,
        // loading: true
      };

    case productConstant.ADD_TO_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        addToFavorite: action.payload,
      };

    case productConstant.GET_FAVORITES_SUCCESS:
      return {
        ...state,
        loading: false,
        favoritesData: action.payload,
      };

    case productConstant.GET_SEARCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstant.GET_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
      };
    case productConstant.GET_SEARCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case productConstant.ADD_COLOR_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        colorMedia: action.payload,
      }
    }
    case productConstant.ADD_COLOR_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        colorMedia: [],
      }
    }

    default:
      return state;
  }
}
