import { toast } from "react-toastify";
import { contactService } from "../adapters/contact.service";
import { useNavigate } from "react-router-dom";

export const contactConstant = {
    CONTACT_REQUEST: "CONTACT_REQUEST",
    CONTACT_SUCCESS: "CONTACT_SUCCESS",
    CONTACT_FAILURE: "CONTACT_FAILURE",

    UPDATE_CONTACT_FLAG: "UPDATE_REGISTER_FLAG",
    RESET_CONTACT_STATE: "RESET_CONTACT_STATE",
};

export const contact = {
    saveData,
    updateContactFlag,
    resetState
};

async function updateContactFlag() {
    return { type: contactConstant.UPDATE_CONTACT_FLAG, payload: '' };
}

async function resetState() {
    return { type: contactConstant.RESET_CONTACT_STATE };
}

async function saveData(data) {
    const response = await contactService.saveData("/api/contact-us/save", data);
    
    if(response.status === 200) {
        toast.success(response?.data?.message);
        return { type: contactConstant.CONTACT_SUCCESS, payload: response.data };
    } else {
        toast.error(response?.data?.message);
        return { type: contactConstant.CONTACT_FAILURE };
    }
}