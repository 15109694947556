import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

function Message() {
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const clearError = (errorType) => {
        dispatch({type : errorType})
    }
    // const clearSuccess = (successType) => {
    //     dispatch({type : successType})
    // }
    // useEffect(() => {
    //     if (authState.success.type === 'signin' && authState.success.message !== null) {
    //        return navigate("/profile");
    //     }
    // },[authState]);

    return (
        <>
            {/* error message */ }
            {authState.error.message ? 
                <div class="alert alert-danger alert-dismissible fade show custom-alert-css" role="alert">
                    <strong className='mr-2'>Error!</strong> {authState.error.message}
                    <button type="button" className="close mr-1" data-dismiss="alert" aria-label="Close" onClick={ () => clearError(authState.error.category) }>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            : ''}
            

            {/* success message */ }
            {/* {authState.success.message ? 
                <div class="alert alert-success alert-dismissible fade show custom-alert-css" role="alert">
                    <strong className='mr-2'>Success!</strong> {authState.success.message}
                    <button type="button" className="close mr-1" data-dismiss="alert" aria-label="Close" onClick={ () => clearSuccess(authState.success.category) }>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            : ''} */}
        </>
    )
}

export default Message
