/************************************************* SLIDERBAR MENU */
import $ from 'jquery';

var j1 = $.noConflict();
j1(function(){
	var $ul   =   j1('.sidebar-navigation > ul');
	
	$ul.find('li a').click(function(e){
		
	  var $li = j1(this).parent();
	  
	  if($li.find('ul').length > 0){
		e.preventDefault();
		
		if($li.hasClass('selected')){
		  $li.removeClass('selected').find('li').removeClass('selected');
		//   $li.find('ul').slideUp(400);
		  $li.find('a em').removeClass('mdi-flip-v');
		}else{
		  
		  if($li.parents('li.selected').length == 0){
			$ul.find('li').removeClass('selected');
			// $ul.find('ul').slideUp(400);
			$ul.find('li a em').removeClass('mdi-flip-v');
		  }else{
			$li.parent().find('li').removeClass('selected');
			// $li.parent().find('> li ul').slideUp(400);
			$li.parent().find('> li a em').removeClass('mdi-flip-v');
		  }
		  
		  $li.addClass('selected');
		  $li.find('>ul').slideDown(400);
		  $li.find('>a>em').addClass('mdi-flip-v');
		}
	  }
	});
	
	
	j1('.sidebar-navigation > ul ul').each(function(i){
	  if(j1(this).find('>li>ul').length > 0){
		var paddingLeft = j1(this).parent().parent().find('>li>a').css('padding-left');
		var pIntPLeft   = parseInt(paddingLeft);
		var result      = pIntPLeft + 20;
		
		j1(this).find('>li>a').css('padding-left',result);
	  }else{
		var paddingLeft = j1(this).parent().parent().find('>li>a').css('padding-left');
		var pIntPLeft   = parseInt(paddingLeft);
		var result      = pIntPLeft + 20;
		
		j1(this).find('>li>a').css('padding-left',result).parent().addClass('selected--last');
	  }
	});
	
	var t = ' li > ul ';
	for(var i=1;i<=10;i++){
	  j1('.sidebar-navigation > ul > ' + t.repeat(i)).addClass('subMenuColor' + i);
	}
	
	var activeLi = j1('li.selected');
	if(activeLi.length){
	  opener(activeLi);
	}
	
	function opener(li){
	  var ul = li.closest('ul');
	  if(ul.length){
		
		  li.addClass('selected');
		  ul.addClass('open');
		  li.find('>a>em').addClass('mdi-flip-v');
		
		if(ul.closest('li').length){
		  opener(ul.closest('li'));
		}else{
		  return false;
		}
		
	  }
	}
	
});
  



/***********************************************Dropdown work on click  if Mobile detect */
// j1(function() {
// 	var md = new MobileDetect(window.navigator.userAgent);
// 	if (md.mobile()) {
// 	}else{
// 		 j1('.dropdown').hover(function() {
// 				j1(this).addClass('open');
// 			},
// 			function() {
// 				j1(this).removeClass('open');
// 		   });
// 	}
// });

/***********************************************Color Check Box in Filter menu */
j1(".image-checkbox").each(function () {
  if (j1(this).find('input[type="checkbox"]').first().attr("checked")) {
    j1(this).addClass('image-checkbox-checked');
  }
  else {
    j1(this).removeClass('image-checkbox-checked');
  }
});
j1(".image-checkbox").on("click", function (e) {
  j1(this).toggleClass('image-checkbox-checked');
  var $checkbox = j1(this).find('input[type="checkbox"]');
  $checkbox.prop("checked",!$checkbox.prop("checked"))

  e.preventDefault();
});


/***********************************************Drop-Down FREEZ - Open even select checkbox or click inside menu */
j1('#myFilter .dropdown-menu').on({
	"click":function(e){
      e.stopPropagation();
    }
});

/***********************************************FILTER MENU IN MOBILE */
function mobFilter() {
  var x = document.getElementById("myFilter");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}

/***********************************************CLOSE FILTER MENU IN MOBILE */
function mobFilterclose() {
  var x = document.getElementById("myFilter");
  if (x.style.display === "block") {
	alert(212);
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}


/* SORT MENU IN MOBILE*/
function mobSort() {
  var x = document.getElementById("mobSort_dd");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}

/*CLOSE SORT MENU IN MOBILE*/
function mobSortclose() {
  var x = document.getElementById("mobSort_dd");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}

/*********************************************** Clear search */
// j1(document).ready(function() {
// 	j1(".clearable").each(function() {
// 			var $inp = j1(this).find("input:text"),
// 			  $cle = j1(this).find(".clearable__clear");
			
// 			$inp.on("input", function(){
// 			$cle.toggle(!!this.value);
// 			});
			
// 			$cle.on("touchstart click", function(e) {
// 			e.preventDefault();
// 			$inp.val("").trigger("input").focus();
// 			});
// 	});
// });
/*********************************************** Whishlist color box */
  j1(function() {
	window.prettyPrint && prettyPrint();
	j1('#colorselector_1').colorselector();
	j1('#colorselector_2').colorselector({
	  callback : function(value, color, title) {
		j1("#colorValue").val(value);
		j1("#colorColor").val(color);
		j1("#colorTitle").val(title);
	  }
	});
  });

/************************************************ QUICK VIEW SLIDER & Zoom */
	  
j1(function() {
	j1('.simpleLens-thumbnails-container img').simpleGallery({
		loading_image: 'img/loading.gif'
	});
	j1('.simpleLens-big-image').simpleLens({
		loading_image: 'img/loading.gif'
	});
});
/************************************************ QUICK VIEW SLIDER IMAGE RESIZE THUMB DIV*/
j1(document).ready(function(){
    resizeContent();
    j1(window).resize(function() {
        resizeContent();
    });	
	j1(".quick_view").on('click', function(event){
		setTimeout(function(){ resizeContent();}, 500);
	});	
});
function resizeContent() {
    // $height = j1('.simpleLens-big-image-container').height();
    j1('.simpleLens-thumbnails-container').height(j1('.simpleLens-big-image-container').height());
}


/************************************************* Wishlist pop up */
// j1(document).ready(function(){
		   
// 	// Get the modal
// 	var modal = document.getElementById('myWishModal');
// 	// Get the button that opens the modal
// 	var btn = document.getElementById("myWhishBtn");
// 	// Get the <span> element that closes the modal
// 	var span = document.getElementsByClassName("close")[0];
// 	// When the user clicks the button, open the modal 
// //https://stackoverflow.com/questions/34779157/opening-multiple-modal-boxes-on-one-page

// 	btn.onclick = function() {		
// 		//alert("dfgdfg");
// 		//modal.style.display = "block";
// 		document.getElementById('myWishModal').style.display = 'block';
// 	}
// 	// When the user clicks on <span> (x), close the modal
// 	span.onclick = function() {
// 		modal.style.display = "none";
// 	}
// 	// When the user clicks anywhere outside of the modal, close it
// 	window.onclick = function(event) {
// 		if (event.target == modal) {
// 			modal.style.display = "none";
// 		}
// 	}
// });
/************************************************* Wishlist pop up -DYNAMIC */

j1(document).ready(function(){

	var offset = j1('.container-desp').offset();
	var height = j1('.container-desp').height();
	var width = j1('.container-desp').width();
	
	if (offset !== undefined) {
		var top = offset.top + height + "px";
		var right = offset.left + width + "px";
	} else {
		var top;
		var right;
	}
	
	
	j1('.whis-modal').css( {
		'position': 'absolute',
		'right': right,
		'top': top
	});
});

/********************************************** Sidebar Sticky */
// j1( document ).ready(function() {
//     var sidebar = new StickySidebar('#sidebar', {
//         containerSelector: '#main-content',
//         innerWrapperSelector: '.sidebar__inner',
//         topSpacing: 48,
//         bottomSpacing: 20
// 	});
// });

/************************************************* OTHER*/

	j1(window).scroll(function() {
		var height = j1(window).scrollTop();
		if (height > 400) {
			j1('.scrollup').fadeIn();
		} else {
			j1('.scrollup').fadeOut();
		}
	});	
	j1(document).ready(function() {
		j1(".scrollup").click(function(event) {
			event.preventDefault();
			j1("html, body").animate({
				scrollTop: 0
			}, "slow");
			return false;
		});
	});



