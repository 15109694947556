import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { contactReducer } from "./contact.reducer";
import { productReducer } from './product.reducer'
import { categoryReducer } from './category.reducer'
import { homeReducer } from './home.reducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
  product: productReducer,
  category: categoryReducer,
  homepage: homeReducer,
});
