import { get, post } from "./xhr";
import { headers } from "../helpers/headers";

export const productService = {
  getProducts,
  getProductById,
  getMedia,
  getBrands,
  getStores,
  productOptionAttr,
  getSearchFilterValue,
  getProductByFilter,
  addToFavorite,
  getFavoritesData,
  getAddTOFavouriteFilter,
  getSearchKeyProduct,
  getColors,
};

function getProducts(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/productList`);
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }
  return get(url, headers);
}

function getProductById(id) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/product/${id}`);
  return get(url, headers);
}

function getMedia(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/media/mediaList`);
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }
  return get(url, headers);
}

function getBrands() {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/brandList`);

  return get(url, headers);
}

function getStores() {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/storeList`);
  return get(url, headers);
  
}
function getColors() {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/colors`);
  return get(url, headers);
  
}

function productOptionAttr() {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/productOptionAttribute`);

  return get(url, headers);
}

/* Getting product by store name */
function getProductByFilter(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/productList`);
  
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }

  return get(url, headers);
}

/* Filter: getting store and brand data here by search */
function getSearchFilterValue(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/${params.filterType}?filter=${params.keyword}`);
  return get(url, headers);

}

/* Add to favorite product */
function addToFavorite(params, token) {
  return post('/api/add-to-favorite', params, token);
}

/* Getting Favorites data here... */
function getFavoritesData(params) {
  return post('/api/favorites', params, params.Token);

  // let url = new URL(`${process.env.REACT_APP_API_URL}/api/favorites`);
  // for (let key in params) {
  //   url.searchParams.append(key, params[key]);
  // }
  
  // return get(url, headers);
}

function getAddTOFavouriteFilter(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/favorites`);
  for (let key in params) {
    url.searchParams.append(key, params[key]);
  }
  
  return get(url, headers);
}


function getSearchKeyProduct(params) {
  let url = new URL(`${process.env.REACT_APP_API_URL}/api/search`);

  for (let key in params) {
      url.searchParams.append(key, params[key]);
  }

  return get(url, headers);
}