import React, { useEffect } from 'react'
import './aboutUs.css'

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='about-page-ht'>
            <section className='about-section pt-50'>
                <div className='product-img'>
                    <img src='images/footer/about-us-1.jpeg' className='about-img'></img>
                </div>
                <div className='product-content-1'>
                    <p className='about-font'>ABOUT US</p>
                    <h3 className='about-heading'>The Platform For Fashion Discovery</h3>
                    {/* <br></br> */}
                    <span className='about-span-text'>We know firsthand how time-consuming it can be to navigate the world of online shopping and to keep track of all your browsing and purchases on various sites. That's why we created Shopthumb – to provide you with a centralized space where you can explore various stores and brands, catering to your wardrobe, beauty, and home decor needs, all in one place. Plus, you can save your favorite finds for future reference.</span><br></br>
                    <u className='about-url'>SHOP BY DESIGNER</u>
                </div>
                <br/>
                <hr width="100%" />
            </section>

            <section className='about-section'>
                <div className='product-img'>
                    <img src='images/footer/about-us-2.jpeg' className='about-img ml-85'></img>
                </div>
                <div className='product-content-2'>
                    <p className='about-font'>OUR MISSION</p>
                    <p className='content-2-line'>Building the best shopping experience...for you.</p>
                    <span>Customers First</span>
                    <h4 className='h4-font'>Customers are at the heart of everything we do. 
                    We are constantly thinking of ways to improve your experience by finding 
                    solutions to all your online shopping challenges. This is just the beginning 
                    — we have a lot more planned for you..</h4>
                    <p>Thank you for being here.</p>
                </div>
                <br/>
                {/* <hr width="100%" /> */}
            </section>

            {/* <section className='about-section mb-50 mb-phone'>
                <div className='product-img'>
                    <img src='images/footer/about-us-3.jpeg' className='about-img'></img>
                </div>
                <div className='product-content-3'>
                    <p className='align-center'>WHAT'S NEXT</p>
                    <h3 className='h3-font center line-ht'>Where Bold Fashion &amp; Technology Meet</h3>
                    <p className='about-last-paragraph'>Moda Operandi was co-founded by Lauren Santo Domingo in 2010 to empower people
                         through exceptional design and fashion. As a former<br></br> Vogue editor, she noticed a
                          disconnect between the bold, expressive<br></br>fashion from the runways and what 
                          she saw in stores. Then, she saw<br></br> an opportunity.<br></br><br></br>
                          Today,Moda carries hundreds of brands and designers across women’s, men’s,
                          fine jewelry, and home, ships to over 125 countries worldwide, and employs 
                          more than 400 people globally.</p>
                    <u className='ml-200'>SHOP LAUREN'S CLOSET</u>
                </div>
                <br/>
                <hr width="100%" />
            </section> */}
        </div>
    )
}

export default AboutUs
