import { homeConstant } from "../actions/home.action";

const initialState = {
    loading: false,
    homepageKey: '',
    homepageProduct: [],
    data: [],
    error: null,
};

export function homeReducer(state = initialState, action) {
    switch (action.type) {
      case homeConstant.GET_HOMEPAGE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case homeConstant.GET_HOMEPAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case homeConstant.GET_HOMEPAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case homeConstant.SET_HOMEPAGE_KEY:
        return {
          ...state,
          loading: false,
          homepageKey: action.payload,
        };
      
      case homeConstant.SET_HOMEPAGE_PRODUCT_KEY:
        return {
          ...state,
          loading: false,
          homepageKey: action.payload,
        };
        
      default:
        return state;
    }
  }