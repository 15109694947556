import * as yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const contactSchema = yup.object().shape({
  full_name: yup.string().min(3, 'Full Name must be at least 3 character').required("Please enter your name"),
  email: yup.string().email('Email must be a valid email').matches(emailRegx, "Please enter the email"),
  phone: yup.string().min(10, 'Phone Number must be at least 10 characters').matches(phoneRegExp, "Please enter your phone number").max(10),
  subject: yup.string().min(5, 'Subject must be at least 5 characters'),
  message: yup.string().min(5, 'Message must be at least 5 characters').required("Please enter your message"),
  
});
