import { toast } from "react-toastify";
import { homeService } from "../adapters/home.services";

export const homeConstant = {
    GET_HOMEPAGE_REQUEST: "GET_HOMEPAGE_REQUEST",
    GET_HOMEPAGE_SUCCESS: "GET_HOMEPAGE_SUCCESS",
    GET_HOMEPAGE_FAILURE: "GET_HOMEPAGE_FAILURE",


    SET_HOMEPAGE_KEY: "SET_HOMEPAGE_KEY",
    SET_HOMEPAGE_PRODUCT_KEY: "SET_HOMEPAGE_PRODUCT_KEY",

};

export const homeAction = {
    getHomepageData,
};


function getHomepageData() {
    return (dispatch) => {
        dispatch(request());
        homeService
        .getHomepageData()
        .then((response) => {
            dispatch(success(response.data));
        })
        .catch((error) => {
            dispatch(errorhome(error));
        });
    };
  
    function errorhome(error) {
        return { type: homeConstant.GET_HOMEPAGE_FAILURE, error: error };
    }
  
    function request() {
        return { type: homeConstant.GET_HOMEPAGE_REQUEST };
    }
  
    function success(data) {
        return { type: homeConstant.GET_HOMEPAGE_SUCCESS, payload: data };
    }
}


