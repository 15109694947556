import React, { useState,  useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams  } from 'react-router-dom';
import ProducstList from './ProducstList';

function HomepageProductList() {
    const {homepageKey} = useParams();
    
    // console.log('homepageKeyProduct--', homepageKey);
    // useEffect(() => {
    //     setHomepageKey(homepage);
    // }, []);
    
    
    return (
        <ProducstList flag='homepageProduct' homepageKey={homepageKey}/>
    )

}

export default HomepageProductList;