import React from "react";

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="product-slider_next product-slider-btn" onClick={onClick}>
            <img src="images/homepage/right-arrow.png" alt="Next" />
        </button>
    );
};

export default CustomNextArrow;