import React, { useState, useEffect } from 'react';
import { FormInput } from '../../utility/FormInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { auth, authConstant } from '../../actions/auth.action';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Message  from '../../components/Message/Message';

const schema = yup.object().shape({
    email: yup.string().email('Email must be a valid email').required("Please enter the email"),
  });

function ForgotPassword() {
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    
    useEffect(() => {
        document.title = 'Shopthumb|Forgot Password'
        if (authState.forgotPasswordFlag === true) {
            dispatch(auth.updateForgotPasswordFlag(authState.userDetails.email));
        
            
            return navigate("/reset-password/success-message");
        }
    },[authState]);

    const onSubmit = async (data) => {
        dispatch({ type: authConstant.FORGOT_PASSWORD_REQUEST });
        dispatch(auth.forgotPassword(data));
    }

    return (
        <section className="col-lg-5 mx-auto my-5">
            <div className="reset-psd">
                <h3 className="text-center">Forgot Password</h3>
                { (authState.error.type === 'forgotPassword') ? <Message /> : '' }
                <p className="mb-3 text-center">Enter your email address below and we will send you an email with a link to reset your password.</p>
                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation ">
                    <div className="position-relative">
                        <FormInput
                            register={register('email')}
                            label="Email"
                            error={errors?.email}
                            name="email"
                            type="text"
                            identity="email"
                            autoComplete="off"
                        />
                    </div>

                    {authState.submitted === true && (
                        <button className="btn btn-dark signup mt-3 mb-2" disabled>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>{" "}
                            Loading...
                        </button>
                    )}

                    {authState.submitted === false && (
                        <button className="btn btn-dark signin" type="submit">submit</button>
                    )}

                    {/* <Link className="btn btn-dark cancel" to='/login'>Back to login</Link> */}
                    {/* <Link to='/login' className='text-center'>Back to login</Link> */}
                    <h6 className="text-center text-dark back-link">
                        <Link to='/login'>Back to login</Link>
                    </h6>
                </form>
                

                <div className="d-none">
                    <h5 className="text-center text-dark">An email has been sent to you at abc@gmail.com that includes a time sensitive link.</h5>
                    <button className="btn btn-dark signin" type="submit">Continue Shopping</button>
                </div>
            </div>
        </section>
    );
}

export default ForgotPassword;