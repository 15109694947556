import React from 'react';
import { Link } from 'react-router-dom';

function ContactUsMessage() {
    
    return (
        <section className="col-lg-6 mx-auto my-5 success-box">
            <div>
                <h3>Thank you for contacting us. We will contact you soon.</h3>
                <Link to="/" className="btn btn-dark signin w-auto">Continue Shopping</Link>
            </div>
        </section>
    );
}

export default ContactUsMessage;