import React from "react";

function QuickViewModal(){
    return(

        <div className="modal fade quick-vmodal" id="quick-view-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="button close" data-dismiss="modal" aria-hidden="true">
                        <svg fill="#c9c9cc9" className="close-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 16" width="18px" height="18px"><path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z"/></svg>
                    </div>

                    <div className="row">
                        {/* <!-- Modal view slider --> */}
                        <div className="col-md-5 col-sm-6 col-xs-12">
                            <div className="aa-product-view-slider">
                                <div className="simpleLens-gallery-container">
                                    <div className="simpleLens-container">
                                        <div className="simpleLens-big-image-container">
                                            <a className="simpleLens-lens-image" data-lens-image="img/quick-img6.jpg">
                                                <img className="img-fluid simpleLens-big-image" src="img/modal-img.jpg"/>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <!-- DEVELOPER NOTE  :: Use Timthumb for resize image of thumbnail | medium |large--> */}
                                    <div className="simpleLens-thumbnails-container">
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img6.jpg" data-big-image="img/quick-img6.jpg">
                                            <img src="img/quick-img6.jpg" />
                                        </a>
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img7.jpg" data-big-image="img/quick-img7.jpg">
                                            <img src="img/quick-img7.jpg" />
                                        </a>
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img8.jpg" data-big-image="img/quick-img8.jpg">
                                            <img src="img/quick-img8.jpg" />
                                        </a>
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img9.jpg" data-big-image="img/quick-img9.jpg">
                                            <img src="img/quick-img9.jpg" />
                                        </a>
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img10.jpg" data-big-image="img/quick-img10.jpg">
                                            <img src="img/quick-img10.jpg"/>
                                        </a>
                                        <a href="#" className="simpleLens-thumbnail-wrapper" data-lens-image="img/quick-img11.jpg" data-big-image="img/quick-img11.jpg">
                                            <img src="img/quick-img11.jpg" />
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal view content --> */}
                        <div className="col-md-7	col-sm-6 col-xs-12">
                            <div className="aa-product-view-content">
                                <h3>T-Shirt</h3>
                                <div className="aa-price-block">
                                    <p className="aa-prod-category">
                                        <a href="#">Polo T-Shirt</a>
                                    </p>
                                    <p className="aa-product-avilability">Avilability: <span>In stock</span></p>
                                </div>

                                <div className="ad_option">
                                    <div className="aa-price-block">
                                        <h4>Price :</h4>
                                        <span className="aa-product-view-price">$158.99</span>
                                    </div>
                                    <div className="aa-prod-view-size">
                                        <h4>Size :</h4>
                                        <a href="#">S</a>
                                        <a href="#">M</a>
                                        <a href="#" className="siz-diseble">L</a>
                                        <a href="#">XL</a>
                                    </div>
                                    <div className="aa-prod-view-color">
                                        <h4>Color :</h4>
                                        <select id="colorselector_2">
                                             <option value="106" data-color="#A0522D">sienna</option>
                                              <option value="47" data-color="#CD5C5C" selected="selected">indianred</option>
                                              <option value="87" data-color="#FF4500">orangered</option>
                                              <option value="17" data-color="#008B8B">darkcyan</option>
                                              <option value="18" data-color="#B8860B">darkgoldenrod</option>
                                              <option value="68" data-color="#32CD32">limegreen</option>
                                              <option value="42" data-color="#FFD700">gold</option>
                                        </select>
                                    </div>
                                    <div className="aa-prod-quantity">
                                        <h4>Qty :</h4>
                                        <div className="number-input">
                                            <button onClick="this.parentNode.querySelector('input[type=number]').stepDown()"></button>
                                            <input className="quantity" min="1" max="49" name="quantity" value="1" type="number" />
                                            <button onClick="this.parentNode.querySelector('input[type=number]').stepUp()" className="plus"></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="quick-desc">
                                    <h4>Details:</h4>
                                    <ul>
                                        <li>Round neck, long bell sleeves, flared cuffs</li>
                                        <li>Sheath silhouette, polka-dot printed ponte</li>
                                        <li>Concealed back zip with hook-and-eye closure, lined</li>
                                        <li>Please note that this dress will be delivered with a black b-tag attached, with instructions for removal included. If the b-tag is removed, the dress cannot be returned.</li>
                                        <li>Polyester/viscose/elastane: lining: polyester</li>
                                        <li>Dry clean</li>
                                        <li>Imported</li>
                                        <li>Web ID: 3185200</li>
                                    </ul>
                                    <a href="#" className="quick_full_desc">See full product info</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row no-margin">
                                <div className="aa-prod-view-bottom bor-top">
                                    <a href="#" className="col-md-6 col-xs-6 aa-add-to-fav-btn">ADD TO FAVORITES</a>
                                    <a href="#" className="col-md-6 col-sm-6 col-xs-6 aa-add-to-cart-btn pull-right">BUY NOW AT BLOOMINGDALE’S</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
    </div>

    )
}

export default QuickViewModal