import React, { useEffect } from 'react'

function About() {
  useEffect(() => {
    document.title = 'About'
  })
  return (
    <div>
      <p>About Page!</p>
    </div>
  )
}
export default About
