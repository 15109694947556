import { get, post } from "./xhr";
import { headers } from "../helpers/headers";

export const homeService = {
    getHomepageData,
};

function getHomepageData() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/homepage/index`);

    return get(url, headers);
}


