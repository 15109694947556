import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from '../../../src/utility/FormInput';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { auth, authConstant } from '../../actions/auth.action';
import Message  from '../../components/Message/Message';

const schema = yup.object().shape({
  password: yup.string().min(8, 'Password must be at least 8 characters').max(50).required("Please enter the password"),
  confirm_password: yup.string().required("Please enter the confirm password").oneOf([yup.ref('password'), null], 'Confirm password must match with password'),
});



function ResetPassword() {
    const authState = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState(true);
    const [showCnfPassword, setShowCnfPassword] = useState(true);

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { id } = useParams();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    useEffect(() => {
        document.title = 'Shopthumb | Reset Password'
        if (authState.resetPassFlag === true) {
            return navigate("/login");
        }
    },[authState]);

    const onSubmit = async (data) => {
        data.id = id;
        dispatch({ type: authConstant.RESET_PASSWORD_REQUEST });
        dispatch(auth.resetPassword(data));
    }

    const togglePassword = () => {
        setShowPassword(showPassword === true ? false : true)
        const loginPasswordEl = document.getElementById('reset-password');
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }

    const togglePasswordCnf = () => {
        setShowCnfPassword(showCnfPassword === true ? false : true)
        const loginPasswordEl = document.getElementById('reset-cnf-password');
        const newType = loginPasswordEl.type === 'password' ? 'text' : 'password'

        return loginPasswordEl.type = newType;
    }

    return (
        <section className="col-lg-5 mx-auto my-5">
            <div className="reset-psd">
                <h3 className="text-center">Reset Password</h3>
                { (authState.error.type === 'resetPassword') ? <Message /> : '' }
                <p className="mb-3 text-center">Please enter a new password for your account.</p>

                <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
                    <div className="position-relative mb-4" id="show_hide_password">
                        <FormInput
                            register={register('password')}
                            label="New Password"
                            error={errors?.password}
                            name="password"
                            type="password"
                            identity="password"
                            autoComplete="off"
                            id="reset-password"
                        />
                        <div className="input-group-addon">
                            <a href onClick={() => togglePassword()}><i className={`fa fa-eye${showPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                        </div>
                    </div>

                    <div className="position-relative" id="show_hide_password2">
                        <FormInput
                            register={register('confirm_password')}
                            label="Confirm New Password"
                            error={errors?.confirm_password}
                            name="confirm_password"
                            type="password"
                            identity="confirm_password"
                            autoComplete="off"
                            id="reset-cnf-password"
                        />
                        <div className="input-group-addon">
                            <a href onClick={() => togglePasswordCnf()}><i className={`fa fa-eye${showCnfPassword === true ? '-slash' : ''}`} aria-hidden="true"></i></a>
                        </div>
                    </div>

                    {authState.submitted === true && (
                        <button className="btn btn-dark signup mt-3 mb-2" disabled>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>{" "}
                            Loading...
                        </button>
                    )}

                    {authState.submitted === false && (
                        <button className="btn btn-dark signin" type="submit">Submit</button>
                    )}

                    <h6 className="text-center text-dark back-link">
                        <Link to='/login'>Back to login</Link>
                    </h6>
                </form>
            </div>
        </section>
    );
}

export default ResetPassword;