import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

function ForgotPasswordMessage() {
    const authState = useSelector((state) => state.auth);
    const userEmail = authState.userDetails.email;
    
    return (
       <>
      
        <section className="col-lg-5 mx-auto my-5 success-box">
        <div>
            <h5 className='mb-4'>Reset password link has been sent to you at {userEmail}. Please click on link and reset your password.</h5>
                <Link className="btn  btn-dark signin"to='/login'>Login</Link>
        </div>
    </section>
    </>
    );
}

export default ForgotPasswordMessage;