import React, { useState, useEffect, useRef } from 'react'
import './style.css'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { auth, authConstant } from '../../actions/auth.action';
import { categoryAction, categoryConstant } from '../../actions/category.action';
import { homeAction, homeConstant } from '../../actions/home.action';
import { productAction, productConstant } from "../../actions/productList.action";
import history from "../../helpers/history";
import ReactGA from 'react-ga';

function Header() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const allCategories = useSelector((state) => state.category?.categories);
    let searchData = useSelector((state) => state.category?.searchResult);
    const searchKeyword = useSelector((state) => state.category?.searchKeywordUrl);
    const [searchKey, setSearchKey] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                // document.querySelectorAll('.openDropwown').forEach(box => {
                //   box.classList.remove('show');
                // });
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    useEffect(() => {
        setSearchResult(searchData);
    }, [searchData])

    useEffect(() => {
        dispatch(categoryAction.getCategories());
    }, [])

    // const handleClick = (e, subCateg) => {
    //   let classList = e.target.parentElement.classList;

    //   if (classList.contains('show')) {
    //     classList.remove('show');
    //   } else {
    //     document.querySelectorAll('.openDropwown').forEach(box => {
    //       box.classList.remove('show');
    //     });
    //     if (subCateg.length > 0) {
    //       classList.add('show');
    //     }
    //   }
    // }

    const logOut = () => {
        dispatch({ type: authConstant.SIGNOUT_REQUEST });
        dispatch(auth.signOut({ Token: authState.userDetails.token }))
    }

    const handleCategoryFilter = (e, url) => {
        dispatch({ type: categoryConstant.SET_CATEGORY_URL_VALUE, payload: url });
    }

    // Handle search suggesstion functionality here...  
    const handleSearchSugg = (e) => {
        e.preventDefault();
        const value = e.target.value
        const button = document.getElementById('crossHeaderButton')

        if (value != '') {
            button.classList.remove('d-none')
        } else {
            button.classList.add('d-none')
        }

        if (e.target.value.length > 2) {
            let keyword = e.target.value.trimEnd().replace(/\s+/g, '+');
            setSearchKey(keyword);
            setSearchResult(searchData);
        } else {
            setSearchResult([]);
            setSearchKey('');
        }
    }

    // Handle search functionality here...
    const handleSearch = (e) => {
        if (searchKey.length > 2) {
            let url = `/search?keyword=${searchKey}`;
            dispatch({ type: categoryConstant.SET_SEARCH_KEYWORD_VALUE, payload: searchKey });
            dispatch({ type: homeConstant.SET_HOMEPAGE_KEY, payload: '' });
            window.location.href = url;
        } else {
            e.target.focus();
        }
    }


    const handleClearSearch = (e) => {
        e.preventDefault();
        setSearchKey('')
        document.getElementById('crossHeaderButton').classList.add("d-none")
        document.getElementsByClassName('search-box')[0].value = '';
        document.getElementsByClassName('search-box')[0].defaultValue = '';
        document.getElementsByClassName('search-box')[0].focus();

    }

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleDropdown = (e, indexValue) => {
        setHoveredIndex(indexValue);

        document.querySelectorAll('.dropdown').forEach((filter) => filter.classList.remove('show'));
    }


    return (
        <>
            <div id="wrapper">
                <header>
                    {/* top header  */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="hdr-top">
                                    <a href="#" id="mob-menu-expand" className="menu-trigger">
                                        <img loading="lazy" src="images/h-menu.png" alt="menu" title="menu" />
                                    </a>

                                    <div className="logo text-center">
                                        <Link to='/'><h1>ShopThumb</h1></Link>
                                    </div>
                                    <div className="log-regi-cur">
                                        <div className="login-register">

                                            <span className="ac-dd">
                                                <div className="dropdown">
                                                    <a
                                                        // className="dropdown-toggle"
                                                        href="#"
                                                        role="button"
                                                        id="myAccount"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span>
                                                            {/* Mobile header login link */}
                                                            <Link to='/login'><i className="fa fa-user u-ico" aria-hidden="true" ></i></Link>

                                                            {authState.userDetails.id ?
                                                                <span className="log-text">
                                                                    <Link to='/profile'>Welcome, {authState.userDetails.first_name}</Link>
                                                                    <Link onClick={() => logOut()} to='/' style={{ pointerEvents: authState.logoutFlag === true ? 'block' : 'none' }}>Logout</Link>
                                                                </span>
                                                                :
                                                                <span className="log-text">
                                                                    <Link to='/login'>Login / Register</Link>
                                                                </span>
                                                            }
                                                            {' '}
                                                        </span>
                                                    </a>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="myAccount"
                                                    >
                                                        <a className="dropdown-item" href="#">
                                                            {' '}
                                                            Login
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            Register
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            My Account
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            My Favorites
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            {' '}
                                                            Logout
                                                        </a>
                                                    </div>
                                                </div>
                                            </span>
                                            {authState.auth && 
                                              <span className="fav-section">
                                                  <Link to='/my-favourite'><i className="fa fa-heart-o" aria-hidden="true"></i></Link>
                                              </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* bottom header  */}
                    <div className="top" data-spy="affix" data-offset-top="90">
                        <nav className="navbar navbar-expand-lg navbar-inverse p-0">
                            <div className=" container-fluid">
                                <div className="custom-menu">
                                    <div className=" navbar-collapse">
                                        <ul className="navbar-nav mr-5">
                                            <li>
                                                {/* <Link to='/products'>Product</Link> */}
                                            </li>

                                            {allCategories?.data?.length > 0 && allCategories.data.map((allCateg, index) => {
                                                const isHovered = index === hoveredIndex;

                                                return (
                                                    <li className={`dropdown  ${isHovered ? 'openDropwown show' : ''}`} key={index} onMouseEnter={(e) => handleDropdown(e, index)} onMouseLeave={() => setHoveredIndex(null)}>

                                                        <a data-toggle="dropdown" href="javascript:void(0)">
                                                            {allCateg.name}
                                                        </a>
                                                        {isHovered && (
                                                            <ul className={`dropdown-menu m-0 ${isHovered ? 'openDropwown show' : ''}`} data-hover="dropdown" style={{zIndex: 999999}}>

                                                                {allCateg.subCateg.length > 0 && allCateg.subCateg.map((subCateg, index) => {
                                                                    if (subCateg.childSubCateg.length > 0) {
                                                                        return (
                                                                            <li key={index}>
                                                                                <a>
                                                                                    <h3>{subCateg.name}</h3>
                                                                                </a>
                                                                                <ul>
                                                                                    {subCateg.childSubCateg.length > 0 && subCateg.childSubCateg.map((childSubCateg, index) => {
                                                                                        return (
                                                                                            <li key={index}>
                                                                                                <Link to={`/browse/${childSubCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allCateg.slug + '/' + subCateg.slug + '/' + childSubCateg.slug)}>{childSubCateg.name}</Link>
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <ul>
                                                                                <li key={index}>
                                                                                    {/* <a href="#">{childSubCateg.name}</a> */}
                                                                                    <Link to={`/browse/${allCateg.slug}/secondary/${subCateg.slug}`} onClick={(e) => handleCategoryFilter(e, '/browse/' + allCateg.slug + '/' + subCateg.slug)}>{subCateg.name}</Link>
                                                                                </li>
                                                                            </ul>
                                                                            // </li>

                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                        )}
                                                    </li>

                                                )
                                            })}

                                        </ul>
                                        <div className="search-clm">
                                            <div className="topsearch-c">
                                                <div className="search-append">
                                                    <form action='/search' name="search_form" onSubmit={(e) => handleSearch(e)}>
                                                        <span className="clearable">
                                                            <input
                                                                className="span2 search-box"
                                                                name="keyword"
                                                                autoComplete="off"
                                                                type="text"
                                                                placeholder="Search"
                                                                onChange={(e) => handleSearchSugg(e)}
                                                                defaultValue={searchKey}
                                                            />
                                                            <div className="clearable__clear d-none" id='crossHeaderButton' onClick={(e) => handleClearSearch(e)}>
                                                                <svg
                                                                    fill="#c9c9cc9"
                                                                    className="close-icons"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 16 16"
                                                                    width="16px"
                                                                    height="16px"
                                                                >
                                                                    <path d="M 2.75 2.042969 L 2.042969 2.75 L 2.398438 3.101563 L 7.292969 8 L 2.042969 13.25 L 2.75 13.957031 L 8 8.707031 L 12.894531 13.605469 L 13.25 13.957031 L 13.957031 13.25 L 13.605469 12.894531 L 8.707031 8 L 13.957031 2.75 L 13.25 2.042969 L 8 7.292969 L 3.101563 2.398438 Z" />
                                                                </svg>
                                                            </div>

                                                            <svg
                                                                fill="#c9c9c9"
                                                                className="search-icon"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 48 48"
                                                                width="24px"
                                                                height="24px"
                                                                onClick={(e) => handleSearch(e)}
                                                            >
                                                                <path d="M 20.5 6 C 12.509634 6 6 12.50964 6 20.5 C 6 28.49036 12.509634 35 20.5 35 C 23.956359 35 27.133709 33.779044 29.628906 31.75 L 39.439453 41.560547 A 1.50015 1.50015 0 1 0 41.560547 39.439453 L 31.75 29.628906 C 33.779044 27.133709 35 23.956357 35 20.5 C 35 12.50964 28.490366 6 20.5 6 z M 20.5 9 C 26.869047 9 32 14.130957 32 20.5 C 32 23.602612 30.776198 26.405717 28.791016 28.470703 A 1.50015 1.50015 0 0 0 28.470703 28.791016 C 26.405717 30.776199 23.602614 32 20.5 32 C 14.130953 32 9 26.869043 9 20.5 C 9 14.130957 14.130953 9 20.5 9 z" />
                                                            </svg>
                                                        </span>
                                                        <div className='dropdown'>
                                                            {searchResult?.products?.length > 0 ? searchResult?.products?.map((product, index) => {
                                                                return (
                                                                    <ul className=''>
                                                                        <li><Link to="#">{product.productName}</Link></li>
                                                                    </ul>
                                                                )
                                                            }) : ''
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {/* mobile menu  */}
                    <div id="menu" className="grey demo-container">
                        {/* <ul id="drilldown-2">
                        <li>
                          <a href="#">Women</a>
                          <ul>
                            <li>
                              <a href="#">Designers</a>
                              <ul>
                                <li>
                                  <a href="#">All Designers (A-Z)</a>
                                  <ul>
                                    <li>
                                      <a href="#">A</a>
                                    </li>
                                    <li>
                                      <a href="#">B</a>
                                    </li>
                                    <li>
                                      <a href="#">C</a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="#">Ulla Johnson</a>
                                </li>
                                <li>
                                  <a href="#">Saint Laurent</a>
                                </li>
                                <li>
                                  <a href="#">Givenchy</a>
                                </li>
                                <li>
                                  <a href="#">Nili Lotan</a>
                                </li>
                                <li>
                                  <a href="#">Isabel Marant</a>
                                </li>
                                <li>
                                  <a href="#">Paco Rabanne</a>
                                </li>
                                <li>
                                  <a href="#">Maison Mayle</a>
                                </li>
                                <li>
                                  <a href="#">Gianvito Rossi</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Clothing</a>
                              <ul className="column">
                                <li>
                                  <a href="#">Skirts</a>
                                </li>
                                <li>
                                  <a href="#">Sweaters</a>
                                </li>
                                <li>
                                  <a href="#">Coats/ Jackets</a>
                                </li>
                                <li>
                                  <a href="#">Dresses</a>
                                </li>
                                <li>
                                  <a href="#">Shorts</a>
                                </li>
                                <li>
                                  <a href="#">Activewear</a>
                                </li>
                                <li>
                                  <a href="#">Pants</a>
                                </li>
                                <li>
                                  <a href="#">Tops</a>
                                </li>
                                <li>
                                  <a href="#">Jeans</a>
                                </li>
                                <li>
                                  <a href="#">Jumpsuits/Rompers</a>
                                </li>
                                <li>
                                  <a href="#">Maternity</a>
                                </li>
                                <li>
                                  <a href="#">Lingerie</a>
                                </li>
                                <li>
                                  <a href="#">Swimwear</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Shoes</a>
                              <ul>
                                <li>
                                  <a href="#">Ballet</a>
                                </li>
                                <li>
                                  <a href="#">Sneakers</a>
                                </li>
                                <li>
                                  <a href="#">Sandals</a>
                                </li>
                                <li>
                                  <a href="#">Loafers & Oxfords</a>
                                </li>
                                <li>
                                  <a href="#">Platforms</a>
                                </li>
                                <li>
                                  <a href="#">Espadrilles</a>
                                </li>
                                <li>
                                  <a href="#">Slingbacks</a>
                                </li>
                                <li>
                                  <a href="#">Pumps</a>
                                </li>
                                <li>
                                  <a href="#">Boots</a>
                                </li>
                                <li>
                                  <a href="#">Booties</a>
                                </li>
                                <li>
                                  <a href="#">Wedges</a>
                                </li>
                                <li>
                                  <a href="#">Mules & Clogs</a>
                                </li>
                                <li>
                                  <a href="#">Mary-Jane</a>
                                </li>
                                <li>
                                  <a href="#">Flats</a>
                                </li>
                                <li>
                                  <a href="#">Rain Boots</a>
                                </li>
                                <li>
                                  <a href="#">Shoe Accessories</a>
                                </li>
                                <li>
                                  <a href="#">Trend: Block Heels</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Bags</a>
                              <ul className="column mayHide">
                                <li>
                                  <a href="#">Totes</a>
                                </li>
                                <li>
                                  <a href="#">Duffels</a>
                                </li>
                                <li>
                                  <a href="#">Baby Bags</a>
                                </li>
                                <li>
                                  <a href="#">Backpacks</a>
                                </li>
                                <li>
                                  <a href="#">Bucket</a>
                                </li>
                                <li>
                                  <a href="#">Beach</a>
                                </li>
                                <li>
                                  <a href="#">Cosmetic pouches</a>
                                </li>
                                <li>
                                  <a href="#">Cross-body</a>
                                </li>
                                <li>
                                  <a href="#">Hobo</a>
                                </li>
                                <li>
                                  <a href="#">Messengers</a>
                                </li>
                                <li>
                                  <a href="#">Tech Accessories</a>
                                </li>
                                <li>
                                  <a href="#">Saddle</a>
                                </li>
                                <li>
                                  <a href="#">Keychains</a>
                                </li>
                                <li>
                                  <a href="#">Mini</a>
                                </li>
                                <li>
                                  <a href="#">Travel</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Accessories</a>
                              <ul className="column mayHide">
                                <li>
                                  <a href="#">Belts</a>
                                </li>
                                <li>
                                  <a href="#">Scarves</a>
                                </li>
                                <li>
                                  <a href="#">Gloves</a>
                                </li>
                                <li>
                                  <a href="#">Sunglasses & Eyewear</a>
                                </li>
                                <li>
                                  <a href="#">Hats</a>
                                </li>
                                <li>
                                  <a href="#">Watches</a>
                                </li>
                                <li>
                                  <a href="#">Socks/ Tights</a>
                                </li>
                                <li>
                                  <a href="#">Umbrella</a>
                                </li>
                                <li>
                                  <a href="#">Hair accessories</a>
                                </li>
                                <li>
                                  <a href="#">Pool Party</a>
                                </li>
                                <li>
                                  <a href="#">Active accessories</a>
                                </li>
                                <li>
                                  <a href="#">Jewelry</a>
                                </li>
                                <li>
                                  <a href="#">Fine Jewelry</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Men</a>
                        </li>
                        <li>
                          <a href="#">Beauty</a>
                        </li>
                        <li>
                          <a href="#">Home</a>
                        </li>
                        <li>
                          <a href="#">Kids</a>
                        </li>
                        <li>
                          <a href="#">cashback</a>
                        </li>
                      </ul> */}
                    </div>
                </header>
                <div className="overlay-class"></div>
            </div>
        </>
    )
}

export default Header
