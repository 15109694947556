import React from "react";

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="product-slider_prev product-slider-btn" onClick={onClick}>
            <img src="images/homepage/left-arrow.png" alt="Previous" />
        </button>
    );
};

export default CustomPrevArrow;